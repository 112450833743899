import axios, { AxiosResponse } from 'axios';

export interface TaxRequest {
  country: string;

  taxNumber: string;
}

export interface TaxResponse {
  taxClauseType: string;
}

export const getTaxInfo = (
  postData: TaxRequest,
  callback: (data: TaxResponse | null) => void,
) => {
  const config = {
    headers: {
      Authorization: process.env.REACT_APP_SPACE_INVOICE_TOKEN || '',
    },
  };

  const postRequest = {
    _documentIssuer: {
      country: process.env.REACT_APP_SNAPGUEST_TAX_COUNTRY,
      taxNumber: process.env.REACT_APP_SNAPGUEST_TAX_NUMBER,
    },
    _documentClient: postData,
  };

  axios
    .post<typeof postRequest, AxiosResponse<TaxResponse>>(
      taxURL(),
      postRequest,
      config,
    )
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      callback(null);
      console.error(err);
    });
};

const baseURL = () => {
  return `${process.env.REACT_APP_SPACE_INVOICE_URL}`;
};

const taxURL = () => {
  return `${baseURL()}/taxes/clause-type`;
};
