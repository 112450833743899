const publicURL = () => {
  return '/public';
};

const protectedURL = () => {
  return '/protected';
};

export const loginURL = () => {
  return `${publicURL()}/users/login`;
};

export const passwordResetURL = () => {
  return `${publicURL()}/users/password/reset`;
};

export const passwordChangeURL = (token: string) => {
  return `${publicURL()}/users/password/change?token=${token}`;
};

export const registerURL = (
  refUser: string | null,
  refCustomer: string | null,
) => {
  let params = '';
  if (refUser !== null && refCustomer !== null) {
    params = `?ref_user=${refUser}&ref_customer=${refCustomer}`;
  }
  return `${publicURL()}/users/register${params}`;
};

export const mailConfirmURL = (token: string) => {
  return `${publicURL()}/users/mail/token/valid?token=${token}`;
};

export const countriesURL = () => {
  return `${publicURL()}/countries`;
};

export const userURL = () => {
  return `${protectedURL()}/users/me`;
};

export const customerURL = () => {
  return `${protectedURL()}/customers/me`;
};

export const facilitiesURL = () => {
  return `${protectedURL()}/facilities`;
};

export const licencesURL = () => {
  return `${protectedURL()}/licences`;
};

export const subscriptionsURL = () => {
  return `${licencesURL()}/subscriptions`;
};

export const subscriptionInfoURL = () => {
  return `${subscriptionsURL()}/info`;
};

export const couponInfoURL = () => {
  return `${subscriptionsURL()}/coupons/info`;
};

export const referralsURL = () => {
  return `${protectedURL()}/referrals/info`;
};

export const licencesReferrlsURL = () => {
  return `${licencesURL()}/referrals`;
};

export const cancelStripeSubscriptionURL = (id: string) => {
  return `/protected/stripe/subscription/${id}`;
};
