class StorageService {
  private storage = window.localStorage;
  private token_key = 'token';
  private token_expire_key = 'token_expire';
  private server_key = 'server';

  getToken() {
    return this.storage.getItem(this.token_key);
  }

  getTokenExpire() {
    const expire = this.storage.getItem(this.token_expire_key);
    if (expire !== null) {
      return new Date(expire);
    }
    return new Date();
  }

  setToken(token: string, expire: Date) {
    this.storage.setItem(this.token_key, token);
    this.storage.setItem(this.token_expire_key, expire.toISOString());
  }

  removeToken() {
    this.storage.removeItem(this.token_key);
    this.storage.removeItem(this.token_expire_key);
  }

  getServer() {
    const server = this.storage.getItem(this.server_key);

    if (server === null) {
      return 'hr';
    }

    return server;
  }

  setServer(server: string) {
    this.storage.setItem(this.server_key, server);
  }

  removeServer() {
    this.storage.removeItem(this.server_key);
  }
}

export default new StorageService();
