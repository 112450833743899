import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useMainStore } from '../../stores/StoresProvider';

import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import CustomerForm from '../../components/form/part/CustomerForm';
import FacilitiesForm from '../../components/form/part/FacilitiesForm';
import PaymentForm from '../../components/form/part/PaymentForm';

const SubscriptionForm: React.FC = () => {
  const { t } = useTranslation();
  const mainStore = useMainStore();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Stepper
          activeStep={mainStore.activeStep}
          alternativeLabel
          ref={mainStore.stepperRef}
        >
          <Step key="customer">
            <StepLabel>{t('home.customer')}</StepLabel>
          </Step>
          <Step key="facilities">
            <StepLabel>{t('home.selectFacilties')}</StepLabel>
          </Step>
          <Step key="payment">
            <StepLabel>{t('home.payment')}</StepLabel>
          </Step>
        </Stepper>
      </Grid>
      {mainStore.activeStep === 0 && (
        <Grid item xs={12}>
          <CustomerForm />
        </Grid>
      )}
      {mainStore.activeStep === 1 && (
        <Grid item xs={12}>
          <FacilitiesForm />
        </Grid>
      )}
      {mainStore.activeStep === 2 && (
        <Grid item xs={12}>
          <PaymentForm />
        </Grid>
      )}
    </Grid>
  );
};

export default observer(SubscriptionForm);
