import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import createSGTheme from './sgTheme';

import Box from '@mui/material/Box';

import RequireAuth from './utils/RequireAuth';
import ScrollToTop from './utils/ScrollToTop';

import { StoresProvider } from './stores/StoresProvider';

import Header from './components/general/Header';
import Footer from './components/general/Footer';
import Loader from './components/general/Loader';

import Install from './components/app/Install';
import MailConfirm from './components/app/MailConfirm';
import RegistrationForm from './components/form/RegistrationForm';
import ResetPasswordForm from './components/form/ResetPasswordForm';
import ChangePasswordForm from './components/form/ChangePasswordForm';
import LoginForm from './components/form/LoginForm';

import User from './pages/User';
import Home from './pages/Home';
import Finish from './pages/Finish';
import Error from './pages/Error';

const theme = createSGTheme();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

function App() {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StoresProvider>
        <ThemeProvider theme={theme}>
          <Elements stripe={stripePromise}>
            <Router>
              <ScrollToTop />
              <Header />
              <Box sx={{ flexGrow: 1 }} pt={6} pb={6}>
                <Routes>
                  <Route path="/app" element={<User />}>
                    <Route
                      path="users/password_reset"
                      element={<ResetPasswordForm />}
                    />
                    <Route
                      path="users/password_change"
                      element={<ChangePasswordForm />}
                    />
                    <Route
                      path="users/mail_validate"
                      element={<MailConfirm />}
                    />
                    <Route
                      path="users/register"
                      element={<RegistrationForm />}
                    />
                    <Route path="users/login" element={<LoginForm />} />
                    <Route path="*" element={<Install />} />
                    <Route path="" element={<Install />} />
                  </Route>
                  <Route
                    path="/finish"
                    element={
                      <RequireAuth>
                        <Finish />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/error"
                    element={
                      <RequireAuth>
                        <Error />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <RequireAuth>
                        <Home />
                      </RequireAuth>
                    }
                  />
                </Routes>
              </Box>
              <Footer />
            </Router>
            <Loader />
          </Elements>
        </ThemeProvider>
      </StoresProvider>
    </Box>
  );
}

export default App;
