import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMainStore } from '../../stores/StoresProvider';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import ServerSelect from '../general/ServerSelect';

import { LoginRequest } from '../../api/models';
import { IconButton, InputAdornment } from '@mui/material';

const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mainStore = useMainStore();
  const [showError, setShowError] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Added state for password visibility

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t('validation.email'))
      .required(t('validation.required')),
    password: yup.string().required(t('validation.required')),
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik<LoginRequest>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mainStore.login(values, (success) => {
        if (success) {
          navigate('/');
        } else {
          setShowError(true);
        }
      });
    },
  });

  const handleGoToResetPass = () => {
    navigate('/app/users/password_reset');
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h4">{t('auth.login')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Alert severity="info">{t('auth.info')}</Alert>
            </Grid>
            <Grid item xs={12}>
              <ServerSelect />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                id="email"
                name="email"
                label={t('auth.email')}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                label={t('auth.password')}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {showError && (
              <Grid item xs={12}>
                <Alert severity="error">{t('auth.invalidAuth')}</Alert>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <Button variant="contained" color="primary" type="submit">
            {t('auth.login')}
          </Button>
        </Grid>
        <Grid item xs="auto">
          <Button variant="text" color="primary" onClick={handleGoToResetPass}>
            {t('auth.forgotPassword')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
