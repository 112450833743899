import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import GooglePlaySVG from '../../assets/images/GooglePlay.svg';
import AppStoreSVG from '../../assets/images/AppStore.svg';

type DownloadButtonProps = {
  url: string;
  icon: string;
  preText: string;
  text: string;
};

const DownloadButton: React.FC<DownloadButtonProps> = ({
  url,
  icon,
  preText,
  text,
}) => {
  const openLink = () => {
    window.open(url, '_blank');
  };

  return (
    <Box
      onClick={openLink}
      sx={{
        backgroundColor: 'primary.main',
        cursor: 'pointer',
        borderRadius: '5px',
        padding: '3% 0% 5% 0%',
      }}
    >
      <Box
        sx={{
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ marginRight: '10px' }}>
          <img src={icon} width="35" height="35" />
        </Box>
        <Box>
          <Box sx={{ fontSize: '12px', fontWeight: '300' }}>{preText}</Box>
          <Box
            sx={{ fontSize: '20px', fontWeight: '600', letterSpacing: '0.5px' }}
          >
            {text}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Install: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      spacing={2}
    >
      <Grid item>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {t('app.download')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DownloadButton
                  url="market://details?id=si.snapguest.app&hl=en&gl=US"
                  icon={GooglePlaySVG}
                  preText="GET IT ON"
                  text="Google Play"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DownloadButton
                  url="https://apps.apple.com/si/app/snapguest-hr/id1499087894"
                  icon={AppStoreSVG}
                  preText="DOWNLOAD ON"
                  text="App Store"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Install;
