import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useMainStore } from '../../stores/StoresProvider';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

const MailConfirm: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const mainStore = useMainStore();

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const token = searchParams.get('token');
    if (token !== null) {
      mainStore.mailConfirm(token, (success) => {
        if (success) {
          setShowSuccess(true);
        } else {
          setShowError(true);
        }
      });
    } else {
      setShowError(true);
    }
  }, [mainStore, searchParams]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      spacing={2}
    >
      <Grid item>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {t('auth.mailConfirm')}
            </Typography>
          </Grid>
          {showSuccess && (
            <Grid item xs={12}>
              <Alert severity="success">{t('auth.mailConfirmed')}</Alert>
            </Grid>
          )}
          {showError && (
            <Grid item xs={12}>
              <Alert severity="error">{t('auth.invalidToken')}</Alert>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MailConfirm;
