import React from 'react';
import { format } from 'date-fns';

type Props = {
  date?: string | null;
  showTime?: boolean;
};

const DateFormat: React.FC<Props> = ({ date, showTime = false }) => {
  if (!date) {
    return null;
  }

  const fDate = format(new Date(date), showTime ? 'Pp' : 'P');

  return <span>{fDate}</span>;
};

export default DateFormat;
