import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMainStore } from '../../stores/StoresProvider';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Loader: React.FC = () => {
  const mainStore = useMainStore();

  return (
    <Backdrop open={mainStore.loader}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default observer(Loader);
