import React from 'react';

import { Outlet } from 'react-router-dom';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const User: React.FC = () => {
  return (
    <Container maxWidth="xs">
      <Grid container direction="column">
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
};

export default User;
