import { createTheme } from '@mui/material/styles';

export default function createSGTheme() {
  const defaultTheme = createTheme({
    typography: {
      fontFamily: ['Roboto', 'Sans-serif'].join(','),
    },
    palette: {
      primary: {
        main: '#d32f2f',
        dark: '#a82424',
      },
      secondary: {
        main: '#F7F7F7',
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            color: 'white',
            backgroundColor: '#F7F7F7',
            boxShadow: 'none',
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            overflow: 'hidden',
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            zIndex: 1600,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: '#4caf50',
          },
          colorSecondary: {
            color: 'white',
            backgroundColor: '#f44336',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            paddingLeft: '4px',
            paddingRight: '8px',
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            listStyle: 'decimal',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            display: 'list-item',
          },
        },
      },
    },
  });

  return defaultTheme;
}
