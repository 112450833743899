import React from 'react';
import { getYear } from 'date-fns';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        bgcolor: 'secondary.main',
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs="auto">
              <Typography variant="body2">
                Snapguest © {getYear(new Date())} -{' '}
                {process.env.REACT_APP_VERSION}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
