import React, { PropsWithChildren } from 'react';

import Box from '@mui/material/Box';

const NoWrap: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
      {children}
    </Box>
  );
};

export default NoWrap;
