import React from 'react';
import { setDefaultOptions } from 'date-fns';
import { hr, enGB, sl } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useMainStore } from '../../stores/StoresProvider';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Logo from '../../assets/images/Logo.png';
import Croatian from '../../assets/images/Croatian.svg';
import English from '../../assets/images/English.svg';
import Slovenian from '../../assets/images/Slovenian.svg';

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const mainStore = useMainStore();

  const handleLogout = () => {
    mainStore.logout();
    location.replace(location.pathname);
  };

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);

    // Date locale
    let locale = hr;

    switch (lang) {
      case 'en':
        locale = enGB;
        break;
      case 'sl':
        locale = sl;
        break;
    }

    setDefaultOptions({
      locale,
    });
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <img src={Logo} height={32} />
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ mr: 1 }}>
            <Select
              value={i18n.language}
              onChange={handleChangeLanguage}
              variant="standard"
            >
              <MenuItem value="hr">
                <img src={Croatian} height={16} />
              </MenuItem>
              <MenuItem value="sl">
                <img src={Slovenian} height={16} />
              </MenuItem>
              <MenuItem value="en">
                <img src={English} height={16} />
              </MenuItem>
            </Select>
          </Box>
          <Box>
            {mainStore.isAuthenticated && (
              <Button variant="outlined" color="primary" onClick={handleLogout}>
                {t('auth.logout')}
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default observer(Header);
