import React, { PropsWithChildren } from 'react';
import MainStore from './MainStore';
import { useLocalObservable } from 'mobx-react';

export const AppStoreContext = React.createContext({} as MainStore);

export const useMainStore = () => {
  const store = React.useContext(AppStoreContext);
  if (!store) {
    throw new Error('useMainStore must be used within a StoresProvider.');
  }
  return store;
};

/**
 * Function that initializes the app's user store.
 */
const createMainStore = (): MainStore => {
  return new MainStore();
};

/** The component wrapping our application with the global state */
export const StoresProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const mainStore = useLocalObservable(() => createMainStore());

  return (
    <AppStoreContext.Provider value={mainStore as MainStore}>
      {children}
    </AppStoreContext.Provider>
  );
};
