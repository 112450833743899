import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router';

import { useMainStore } from '../stores/StoresProvider';

const RequireAuth: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const mainStore = useMainStore();

  if (mainStore.isAuthenticated) {
    return <>{children}</>;
  } else {
    return <Navigate to="/app/users/login" />;
  }
};

export default observer(RequireAuth);
