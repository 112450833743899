import i18n from 'i18next';
import { setDefaultOptions } from 'date-fns';
import { hr as dateHr, enGB, sl as dateSl } from 'date-fns/locale';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import en from './assets/i18n/en.json';
import hr from './assets/i18n/hr.json';
import sl from './assets/i18n/sl.json';

// Translations
const resources = {
  en: {
    translation: en,
  },
  hr: {
    translation: hr,
  },
  sl: {
    translation: sl,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'hr',
    returnNull: false,
    supportedLngs: Object.keys(resources),

    detection: {
      order: ['querystring', 'navigator'],
    },

    interpolation: {
      escapeValue: false,
    },
  });

// Date locale
let locale = dateHr;

switch (i18n.language) {
  case 'en':
    locale = enGB;
    break;
  case 'sl':
    locale = dateSl;
    break;
}

setDefaultOptions({
  locale,
});

export default i18n;
