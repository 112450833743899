import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useMainStore } from '../../stores/StoresProvider';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ReferralForm from '../../components/form/ReferralForm';

const Referrals: React.FC = () => {
  const { t } = useTranslation();
  const mainStore = useMainStore();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4">{t('home.referrals')}</Typography>
      </Grid>
      {mainStore.referralInfo && (
        <Grid item xs={12}>
          <ReferralForm />
        </Grid>
      )}
    </Grid>
  );
};

export default observer(Referrals);
