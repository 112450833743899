import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useMainStore } from '../../stores/StoresProvider';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

export enum ServerOption {
  Slovenia = 'Slovenia (eTurizem)',
  Croatia = 'Croatia (eVisitor)',
}

const ServerSelect: React.FC = () => {
  const { t } = useTranslation();
  const mainStore = useMainStore();

  const handleChangeServer = (event: SelectChangeEvent) => {
    const server = event.target.value;

    mainStore.changeServer(server);
  };

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{t('auth.server')}</InputLabel>
      <Select
        label={t('auth.server')}
        value={mainStore.server}
        onChange={handleChangeServer}
        variant="outlined"
      >
        <MenuItem value="hr">{ServerOption.Croatia}</MenuItem>
        <MenuItem value="si">{ServerOption.Slovenia}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default observer(ServerSelect);
