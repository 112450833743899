import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useMainStore } from '../stores/StoresProvider';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';

import Referrals from '../components/home/Referrals';
import Licence from '../components/home/Licence';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const mainStore = useMainStore();

  useEffect(() => {
    if (mainStore.countries === null) {
      mainStore.getCountries();
    }
    if (mainStore.facilities === null) {
      mainStore.getFacilities();
    }
    if (mainStore.licences === null) {
      mainStore.getLicences();
    }
    if (mainStore.licencePaymentInfos === null) {
      mainStore.getSubscriptionInfo();
    }
    if (mainStore.referralInfo === null) {
      mainStore.getReferralsInfo();
    }
    if (mainStore.subscriptions === null) {
      mainStore.getSubscriptions();
    }
  }, [mainStore]);

  const isLoaded = mainStore.user && mainStore.customer;

  const isOwner =
    mainStore.user &&
    mainStore.customer &&
    mainStore.user.id === mainStore.customer.ownerId;

  const handleClose = () => {
    window.open(process.env.REACT_APP_GO_BACK_LINK, '_blank');
  };

  return (
    <Container maxWidth="md">
      {isLoaded ? (
        isOwner ? (
          <Grid container justifyContent="center" spacing={10}>
            <Grid item xs={12}>
              <Referrals />
            </Grid>
            <Grid item xs={12}>
              <Licence />
            </Grid>
            <Hidden smUp>
              <Grid item xs="auto">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  {t('general.close')}
                </Button>
              </Grid>
            </Hidden>
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            <Grid item xs="auto">
              <Alert severity="warning">{t('home.notOwner')}</Alert>
            </Grid>
          </Grid>
        )
      ) : (
        <div></div>
      )}
    </Container>
  );
};

export default observer(Home);
