import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useMainStore } from '../../stores/StoresProvider';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import LicenceList from '../../components/list/LicenceList';
import SubscriptionForm from '../../components/form/SubscriptionForm';

const Subscription: React.FC = () => {
  const { t } = useTranslation();
  const mainStore = useMainStore();

  const hasActiveLicences =
    mainStore.facilities && mainStore.licences && mainStore.licences.length > 0;

  const hasPaymentInfo =
    mainStore.customer &&
    mainStore.facilities &&
    mainStore.licencePaymentInfos &&
    mainStore.licencePaymentInfos.length > 0;

  return (
    <Grid container spacing={10}>
      {hasActiveLicences && (
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h4">{t('home.activeLicences')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <LicenceList />
            </Grid>
          </Grid>
        </Grid>
      )}
      {hasPaymentInfo && (
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h4">{t('home.renewLicences')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <SubscriptionForm />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default observer(Subscription);
