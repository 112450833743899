import React, { ChangeEvent, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useMainStore } from '../../../stores/StoresProvider';
import { StripeCardElementChangeEvent, StripeError } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import DateFormat from '../../../utils/DateFormat';

import MasterCard from '../../../assets/images/MasterCard.svg';
import Visa from '../../../assets/images/Visa.svg';
import {
  PaymentMethod,
  Subscription,
  SubscriptionState,
} from '../../../api/models';

const PaymentForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mainStore = useMainStore();
  const stripe = useStripe();
  const elements = useElements();

  const [couponCode, setCouponCode] = useState('');

  const [sendingData, setSendingData] = useState(false);

  const [validCard, setValidCard] = useState(false);
  const [cardError, setCardError] = useState<string | undefined>(undefined);

  const discountAmount = mainStore.couponDiscount;

  const handleGetCouponInfo = () => {
    mainStore.getCouponInfo(couponCode);
  };

  const handleChangePaymentType = (
    _event: React.MouseEvent<HTMLElement>,
    type: PaymentMethod,
  ) => {
    if (type) {
      mainStore.setPaymentMethod(type);
    }
  };

  const handleCardPayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    setSendingData(true);

    mainStore.createSubscription(
      {
        facilityIds: mainStore.selectedPaymentInfos.map((p) => p.facilityId),
        paymentMethod: mainStore.paymentMethod,
        couponCode: mainStore.validCupon ? couponCode : undefined,
      },
      async (subscription) => {
        if (subscription) {
          mainStore.showLoader();

          if (subscription.state === SubscriptionState.trial) {
            const { error } = await stripe.confirmCardSetup(
              subscription.serviceClientSecret,
              {
                payment_method: {
                  card: cardElement,
                },
              },
            );

            handlePaymentResponse(subscription, error);
          } else {
            const { error } = await stripe.confirmCardPayment(
              subscription.serviceClientSecret,
              {
                payment_method: {
                  card: cardElement,
                },
              },
            );

            handlePaymentResponse(subscription, error);
          }
        } else {
          setSendingData(false);
        }
      },
    );
  };

  const handlePaymentResponse = (
    subscription: Subscription,
    error?: StripeError,
  ) => {
    setSendingData(false);

    if (error) {
      mainStore.setPaymentError(error);

      mainStore.cancelStripeSubscription(subscription.serviceId, () => {
        navigate('/error');
      });
    } else {
      mainStore.getSubscriptionInfo();

      navigate('/finish');
    }

    mainStore.setStep(0);
  };

  const handleBankTransfer = async () => {
    setSendingData(true);

    mainStore.createSubscription(
      {
        facilityIds: mainStore.selectedPaymentInfos.map((p) => p.facilityId),
        paymentMethod: mainStore.paymentMethod,
        couponCode: mainStore.validCupon ? couponCode : undefined,
      },
      async () => {
        setSendingData(false);

        navigate('/finish');
      },
    );
  };

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('licencePaymentInfo.facility')}</TableCell>
                <TableCell>{t('licencePaymentInfo.beds')}</TableCell>
                <TableCell>{t('licencePaymentInfo.price')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mainStore.selectedPaymentInfos?.map((info) => {
                const facility = mainStore.facilities?.find(
                  (f) => f.id === info.facilityId,
                );

                const price = mainStore.priceFormatter.format(info.price / 100);

                return (
                  <TableRow key={info.facilityId}>
                    <TableCell>{facility?.name}</TableCell>
                    <TableCell>
                      {info.beds === -1 ? '50+' : info.beds}
                    </TableCell>
                    <TableCell>{price}</TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={2} align="right">
                  <b>
                    {t('home.total')}
                    {':'}
                  </b>
                </TableCell>
                <TableCell>
                  <b>{mainStore.totalPrice}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="right">
                  <b>
                    {t('home.validFrom')}
                    {':'}
                  </b>
                </TableCell>
                <TableCell colSpan={2}>
                  <b>
                    <DateFormat date={mainStore.subscriptionRange.start} />
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="right">
                  <b>
                    {t('home.validTo')}
                    {':'}
                  </b>
                </TableCell>
                <TableCell colSpan={2}>
                  <b>
                    <DateFormat date={mainStore.subscriptionRange.end} />
                  </b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          label={t('home.coupon')}
          value={couponCode}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setCouponCode(e.target.value)
          }
          onBlur={handleGetCouponInfo}
          error={mainStore.invalidCupon}
          helperText={mainStore.invalidCupon && t('home.couponInvalid')}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            className: 'data-hj-allow',
          }}
        />
      </Grid>
      {mainStore.validCupon && (
        <Grid item xs={12}>
          <Alert severity="success">
            <AlertTitle>
              <Trans
                i18nKey="home.couponDiscount"
                discountAmount={discountAmount}
              >
                Cupon for {{ discountAmount }} off added
              </Trans>
            </AlertTitle>
            {t('home.couponInfo')}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <ToggleButtonGroup
          color="primary"
          value={mainStore.paymentMethod}
          exclusive
          onChange={handleChangePaymentType}
          size="large"
        >
          <ToggleButton value={PaymentMethod.CARD}>
            <CreditCardIcon />
            &nbsp;&nbsp;{t('home.card')}
          </ToggleButton>
          <ToggleButton value={PaymentMethod.BANK_TRANSFER}>
            <AccountBalanceIcon />
            &nbsp;&nbsp;
            {t('home.bankTransfer')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {mainStore.paymentMethod === PaymentMethod.CARD && (
        <Grid item xs={12}>
          <Typography variant="body1">{t('home.creaditCardLabel')}</Typography>
          <Box
            sx={{
              border: '1px solid',
              borderRadius: 1,
              borderColor: 'rgba(0, 0, 0, 0.23)',
              mt: 1,
              p: 2,
            }}
          >
            <CardElement
              onChange={(event: StripeCardElementChangeEvent) => {
                if (event.error) {
                  setCardError(event.error.message);
                } else {
                  setCardError(undefined);
                }
                setValidCard(event.complete);
              }}
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#f44336',
                  },
                },
                hidePostalCode: true,
              }}
            />
          </Box>
          <Box>
            <img src={MasterCard} height={48} />
            <img src={Visa} height={48} />
          </Box>
        </Grid>
      )}
      {cardError && (
        <Grid item xs={12}>
          <Alert severity="error">{cardError}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" spacing={4}>
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="primary"
              disabled={
                (!stripe || !validCard || sendingData) &&
                mainStore.paymentMethod === PaymentMethod.CARD
              }
              onClick={
                mainStore.paymentMethod === PaymentMethod.CARD
                  ? handleCardPayment
                  : handleBankTransfer
              }
            >
              {t('general.activate')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {mainStore.paymentMethod === PaymentMethod.CARD && (
        <Grid item xs={12}>
          <Alert severity="info">
            <AlertTitle>{t('home.paymentInfo')}</AlertTitle>
            <List dense disablePadding component="ol">
              <ListItem disableGutters>
                <ListItemText primary={t('home.paymentInfoStepOne')} />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText primary={t('home.paymentInfoStepTwo')} />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText primary={t('home.paymentInfoStepThree')} />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText primary={t('home.paymentInfoStepFour')} />
              </ListItem>
            </List>
          </Alert>
        </Grid>
      )}
      {mainStore.paymentMethod === PaymentMethod.BANK_TRANSFER && (
        <Grid item xs={12}>
          <Alert severity="info">
            <AlertTitle>{t('home.transferTitle')}</AlertTitle>
            <List dense disablePadding component="ol">
              <ListItem disableGutters>
                <ListItemText>
                  <Trans
                    i18nKey="home.transferInfo"
                    components={{ bold: <strong /> }}
                  />
                </ListItemText>
              </ListItem>
            </List>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default observer(PaymentForm);
